import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Header } from 'src/components/Header';
import { HomePage } from 'src/components/HomePage';
import { Footer } from 'src/components/Footer';

export const Page1: React.FC<RouteComponentProps> = () => (
  <div>
    <Header />
    <HomePage />
    <Footer />
  </div>
);

Page1.displayName = 'Homepage';

export default Page1;
