import * as React from 'react';
import { useLogger } from '@shared-ui/logger-context';
import { UitkPrimaryButton } from 'uitk-react-button';

export const HomePage: React.FC = () => {
  const { info } = useLogger();
  info('Rendering Homepage Content');

  return (
    <main className="sp_home">
      <div className="sp_block_1" style={{ backgroundImage: 'url(/images/home_top_1.jpeg' }}>
        <div className="container">
          <h1>
            Welcome to <br /> Stay Neighborly
          </h1>
          <p>
            <span className="tm-box">
              by Vrbo <span className="tm-icon">&#174;</span>
            </span>
          </p>
        </div>
      </div>

      <div className="sp_block_2">
        <div className="container">
          <h2 className="ttl2">
            Stay Neighborly reflects Vrbo’s commitment to the benefits that short-term rentals provide homeowners,
            travelers, and the communities in which they are a part.
          </h2>
          <p>
            Vrbo is committed to educating our owners and travelers on a short-term rental’s responsibility to its
            community, and provide a communication portal to alert Vrbo to disruptive behavior.
          </p>
        </div>
      </div>

      <div className="sp_block_3" style={{ backgroundImage: 'url(/images/home_bot_2.jpeg' }}>
        <div className="container">
          <h2 className="ttl2">Stay Neighborly Issue Form</h2>
          <p>
            If you believe your neighbor’s property is listed on Vrbo and you have a concern regarding nuisance issues,
            we want to know.
          </p>
          <p>
            Please use the link below to submit your concern. Our customer service team will review the submission. If
            the complaint matches a property on Vrbo, we will alert the owner to your concerns.
          </p>
          <a href="formpage" className="btn_a">
            <UitkPrimaryButton className="btn">Submit Concern Here</UitkPrimaryButton>
          </a>
        </div>
      </div>
    </main>
  );
};
